<template>
  <div class="main_container" v-loading="loading">
    <div class="main_page">
      <div class="page_title">
        今日概况<span class="sub_title" v-if="day">更新时间：{{ day }}</span>
      </div>
      <div class="page_containt">
        <div class="home_data">
          <div class="data_item">
            <div class="name">用户访问次数</div>
            <div class="num">{{ report ? report.t_num : 0 }}</div>
            <div class="name sub_name">昨日：{{ report ? report.y_num : 0 }}</div>
          </div>
          <div class="data_item">
            <div class="name">用户新增数量</div>
            <div class="num">{{ report ? report.t_vip_num : 0 }}</div>
            <div class="name sub_name">昨日：{{ report ? report.y_vip_num : 0 }}</div>
          </div>
          <div class="data_item">
            <div class="name">总用户数</div>
            <div class="num">{{ report ? report.all_vip_num : 0 }}</div>
          </div>
          <div class="data_item"  style="text-align:center">
            <div class="name">小程序码</div>
            <div class="num">
              <el-image style="width: 80px; height: 80px" :src="wxQr" :preview-src-list="[wxQr]" :fit="fit"
                v-if="wxQr"></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main_page" style="margin-top: 12px">
      <div class="page_title">统计</div>
      <div class="page_containt">
        <div class="chart_box">
          <div class="chart_item">
            <div ref="vipChart" class="chart"></div>
            <div class="chart_name">近七天用户新增数量</div>
          </div>
          <div class="chart_item">
            <div ref="paperChart" class="chart"></div>
            <div class="chart_name">近七天用户访问次数</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const echarts = require("echarts");
import appApi from "@/api/app";
export default {
  name: "home",
  data() {
    return {
      loading: false,
      report: null,
      day: "",
      wxQr: "",
    };
  },
  created() { },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.loading = true;
      appApi
        .home()
        .then((res) => {
          this.day = res.day;
          this.report = res.report;
          this.wxQr = res.qr;
          this.drawLine(res.vip_chart, res.login_chart);
        })
        .catch(() => { })
        .finally(() => {
          this.loading = false;
        });
    },
    drawLine(vipCartJson, paperChartJson) {
      let vipChartBox = this.$refs.vipChart;
      let vipChart = echarts.init(vipChartBox);
      let vipOption = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          show: false
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: vipCartJson.days,
        },
        yAxis: {
          type: "value",
          minInterval: 1,
        },
        series: [
          {
            name: "数量",
            type: "line",
            stack: "数量",
            data: vipCartJson.val,
          }
        ],
      };

      vipOption && vipChart.setOption(vipOption);

      let paperChartBox = this.$refs.paperChart;
      let paperChart = echarts.init(paperChartBox);
      let paperOption = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          show: false
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: paperChartJson.days,
        },
        yAxis: {
          type: "value",
          minInterval: 1,
        },
        series: [
          {
            name: "数量",
            type: "line",
            stack: "数量",
            data: paperChartJson.val,
          }
        ],
      };

      paperChart && paperChart.setOption(paperOption);
    },
  },
};
</script>